@import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
@import 'normalize.css';
@import '../node_modules/@angular/material/prebuilt-themes/indigo-pink.css';
@import '../node_modules/ngx-toastr/toastr.css';
@import '../node_modules/vanillajs-datepicker/dist/css/datepicker-bs5.min.css';
@import './global_styles/datepicker.scss';
@import '@ng-select/ng-select/themes/default.theme.css'; // ng-select styles
@import './global_styles/ng-select.scss'; // override ng-select styles
@import './global_styles/full-calendar.scss'; // override FullCalendar styles
@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,300;0,400;0,600;1,300;1,400;1,600&display=swap');
@import './global_styles/aw-wizard.scss';
@import './global_styles/aw-forms.scss';

:root {
    // Bootstrap variables
    --bs-font-sans-serif: 'Barlow', sans-serif;
    --body-font-family: 'Barlow', sans-serif;
    --body-color: #edeef2;
    --primary-color-light: #29e1b4;
    --primary-color-dark: #67c2ac;
    --secondary-color-light: #0150b7;
    --secondary-color-dark: #03045e;
    --bs-body-color: #03045e;
    --link-color: var(--primary-color-light);
    --font-color: var(--secondary-color-dark);
    --gray-border: rgba(0, 0, 0, 0.15);
    --gray-light: rgba(0, 0, 0, 0.4);
    --radius: 6px;
    --form-width: 400px;
    --error-color: #d81e5b;

    // Airwallet variables
    --aw-text-color: #03045e;
    --aw-btn-text-color: #fff;
    --primary-color-light-darker: #34dbb2;
    --aw-border-color: #bec9d1;

    // FullCalendar stylings
    // for full list of root variables: https://github.com/fullcalendar/fullcalendar/blob/main/packages/core/src/styles/vars.css
    --fc-small-font-size: 0.85em;
    --fc-page-bg-color: #fff;
    --fc-neutral-bg-color: rgba(208, 208, 208, 0.3);
    --fc-neutral-text-color: var(--aw-text-color);
    --fc-border-color: var(--aw-border-color);

    --fc-button-text-color: var(--aw-btn-text-color);
    --fc-button-bg-color: var(--primary-color-light);
    --fc-button-border-color: var(--primary-color-light);
    --fc-button-hover-bg-color: var(--primary-color-dark);
    --fc-button-hover-border-color: var(--primary-color-dark);
    --fc-button-active-bg-color: var(--primary-color-dark);
    --fc-button-active-border-color: var(--primary-color-dark);

    --fc-event-bg-color: var(--primary-color-light-darker);
    --fc-event-border-color: var(--primary-color-light-darker);
    --fc-event-text-color: var(--aw-btn-text-color);
    --fc-event-selected-overlay-color: rgba(0, 0, 0, 0.25);

    --fc-more-link-bg-color: var(--aw-btn-text-color);
    --fc-more-link-text-color: var(--aw-text-color);

    --fc-event-resizer-thickness: 8px;
    --fc-event-resizer-dot-total-width: 8px;
    --fc-event-resizer-dot-border-width: 1px;

    --fc-non-business-color: rgba(215, 215, 215, 0.3);
    --fc-bg-event-color: rgb(143, 223, 130);
    --fc-bg-event-opacity: 0.3;
    --fc-highlight-color: rgba(188, 232, 241, 0.3);
    --fc-today-bg-color: transparent; // hiding today indicator (default highlighted in yellow)
    --fc-now-indicator-color: transparent; // hiding now indicator (default is red)

    // https://github.com/fullcalendar/fullcalendar/blob/main/packages/daygrid/src/styles/vars.css
    --fc-daygrid-event-dot-width: 8px;

    // https://github.com/fullcalendar/fullcalendar/blob/main/packages/list/src/styles/vars.css
    --fc-list-event-dot-width: 10px;
    --fc-list-event-hover-bg-color: #f5f5f5;
}

/* POST(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
    - Keep an eye out for whether or not this styling is redundant */
body,
.my-tool-tip *,
.pac-container *,
.mat-mdc-paginator *,
.mat-select-panel-wrap * {
    font-family: 'Barlow', sans-serif;
}

mat-tooltip-component {
    display: none !important; // removes the tooltip from mat-paginator first, prev, next and last buttons
}

body {
    margin: 0 !important;
    padding: 0 !important;
    background-color: var(--body-color);
    overflow-x: hidden;
    overflow-y: auto;
    touch-action: manipulation;
}

.noscroll {
    overflow-y: hidden !important;
}

.animation-outer {
    position: relative;

    .animation-inner {
        position: absolute;
        width: 100%;
    }
}

.otp-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: 92%;
    position: relative; /* Add relative positioning */

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 90%;
        border-bottom: 4px solid var(--primary-color-light);
        border-bottom-left-radius: 2px;
        border-bottom-right-radius: 2px;
        transition: border-bottom 5s ease;
    }

    span {
        transform: scale(0.6, 1.2);
    }
}

@media screen and (min-width: 960px) {
    html {
        margin-right: calc(-100vw + 100%);
    }
}

@media (max-width: 768px) {
    input {
        font-size: 16 px;
    }

    input:focus {
        font-size: 16px;
    }

    @media screen and (-webkit-min-device-pixel-ratio: 0) {
        select,
        textarea,
        input {
            font-size: 16px;
        }
    }

    @media screen and (-webkit-min-device-pixel-ratio: 0) {
        select:focus,
        textarea:focus,
        input:focus {
            font-size: 16px;
        }
    }
}

p,
li,
label,
input,
h1,
h2,
h3,
h4,
h5,
h6 {
    color: var(--secondary-color-dark);
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 600;
}

h1 {
    font-size: 32px;
}

.danger-text-color {
    color: #d81e5b;
}

.form-select {
    color: #03045e;
    background-image: url('./assets/icons/select_input_icon_enabled.svg');
    background-size: 6.5px 11.394px;
}

.form-control:disabled {
    color: #03045e;
    -webkit-text-fill-color: #03045e; // safari
    opacity: 1; // required on iOS
    background-color: #ebeff1;
}

.no-items-in-list {
    cursor: default !important;
    box-shadow: none !important;
    background-color: #f8fafb;
    padding: 1rem;
}

.data-select,
.data-select:active,
.data-select:focus {
    padding: 0.375rem 1.5rem 0.375rem 0.75rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.form-select.datepicker-input {
    background-image: url('./assets/icons/select_date_picker.svg');
    background-size: 1rem;
}

.form-select:disabled {
    background-color: #ebeff1;
    cursor: inherit;
    background-image: url('./assets/icons/select_input_icon_disabled.svg');
    border: 1px solid #bec9d1;
}

.form-select.select-pristine {
    color: var(--aw-border-color);
}

.form-select::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--aw-border-color) !important;

    opacity: 1;
    /* Firefox */
}

.form-select:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: var(--aw-border-color) !important;
}

.form-select::-ms-input-placeholder {
    /* Microsoft Edge */
    color: var(--aw-border-color) !important;
}

.ng-placeholder {
    color: var(--aw-border-color) !important;
}

input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--aw-border-color) !important;

    opacity: 1;
    /* Firefox */
}

input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: var(--aw-border-color) !important;
}

input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: var(--aw-border-color) !important;
}

.noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

.aw-hr {
    margin: 1.125rem 0rem;
    border-bottom: 1px solid var(--aw-border-color);
}

.aw-dropdown-options-btn-loading {
    // this is a style for in button loading on animated dropdown (multiple options), i.e. used on edit device page
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1rem;
    padding: 4.5px 15px;
}

.page-title {
    font-size: 1.875rem;
    margin-bottom: 0px;
}

.title-area {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.plus-icon-on-btn {
    margin: 0px 3px 3px 0px;
}

// fix for append box on input fields
.input-group-append {
    span,
    div {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}

select {
    overflow: hidden !important;
    text-overflow: ellipsis;
    white-space: nowrap;
}

a[role='button'] {
    color: var(--secondary-color-dark);
    text-decoration: underline;
}

a:link,
a:visited,
a:hover,
a:active,
a:focus {
    color: var(--secondary-color-dark);
    text-decoration: underline;
}

/* focused link */
a:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    border-radius: 0.3125rem;
    /* For Windows high contrast mode. */
    outline: 3px solid transparent;
}

a.disabled-link {
    cursor: default !important;
    outline: none !important;
    border-color: transparent !important;
    box-shadow: none !important;
}

button:disabled * {
    button[disabled] * {
        background-color: #bec9d1;
    }
}

/* POST(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.
    - Keep an eye out for whether or not this styling is redundant */
#matpage *,
.mat-mdc-option__text {
    color: var(--secondary-color-dark);
    font-size: 14px;

    button:disabled *,
    button[disabled] * {
        color: #bec9d1;
    }
}

.empty-page-list {
    background-color: #dfe3e9;
    border-radius: 0.625rem;
    margin-bottom: 1rem;

    p {
        margin: 0;
        padding: 1.625rem 1.5rem;
    }
}

.loading-height {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4.75rem;
    margin-bottom: 1rem;
}

.my-mini-btn {
    padding: 0px;
    margin: 0 0 0 5px;
    background: transparent;
    border: none;
    outline: none;
    align-content: center;
    display: flex;

    img {
        align-self: center;
        width: 14px;
        height: 14px;
    }
}

.horizonatal-line {
    border-top: 1px solid var(--aw-border-color);
    opacity: 1;
}

.clip {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.copied {
    font-weight: 600;
    color: #67c2ac;
}

.box {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 3px 6px #00000029;
}

form.submitted input.ng-invalid,
form.submitted select.ng-invalid,
select.ng-dirty.ng-invalid {
    border: 1px solid lightcoral;
}

.success-msg {
    color: var(--primary-color-light);
}

.primary-color {
    color: var(--primary-color-light) !important;
}

.secondary-color {
    color: var(--secondary-color-dark) !important;
}

.right-margin-btn {
    margin-right: 19px;
}

.small-right-margin-btn {
    margin-right: 8px;
}

.animated-button-btns-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 150px;

    button {
        width: 100%;
        margin-bottom: 0.75rem;
    }
}

.btn {
    transition: 0s !important;
    white-space: nowrap; // to make sure button text is not wrapping
    border: none;
    border-radius: 5px;
    color: white !important;
    font-size: 1.25rem;
    font-weight: 400;
    padding: 11px 33px;
    height: 52px;
}

.btn-primary {
    background: transparent linear-gradient(90deg, var(--primary-color-dark) 0%, var(--primary-color-light) 100%) 0% 0% no-repeat padding-box;
}

.btn-primary:hover:not([disabled]) {
    background: var(--primary-color-dark) !important;
}

.btn-secondary {
    background: transparent linear-gradient(90deg, var(--secondary-color-dark) 0%, #0150b7 100%) 0% 0% no-repeat padding-box;
}

.btn-secondary-disabled {
    background: #bec9d1 !important;
    color: white !important;
}

.btn-secondary:hover:not([disabled]) {
    background: var(--secondary-color-dark) !important;
}

.btn-secondary-force-hover-effect {
    background: var(--secondary-color-dark) !important;
}

.btn-danger {
    background: transparent linear-gradient(90deg, #d81e5b 0%, #fe5e92 100%) 0% 0% no-repeat padding-box;
    color: white !important;
}

.btn-danger:hover,
.btn-danger:active,
.btn-danger:focus {
    background: #d81e5b !important;
}

.btn-warning {
    background: transparent linear-gradient(90deg, #fc9124 0%, #ffb66b 100%) 0% 0% no-repeat padding-box;
    color: white !important;
}

.btn-warning:hover,
.btn-warning:active,
.btn-warning:focus {
    background: #fc9124 !important;
}

.btn:disabled,
.btn:disabled:active {
    cursor: default;
    pointer-events: all !important;
    background: var(--aw-border-color);
}

.btn-link {
    border: none !important;
    color: var(--primary-color-light) !important;
    background-color: transparent !important;
}

.btn-link-dark {
    border: none !important;
    color: var(--secondary-color-light) !important;
    background-color: transparent !important;
}

.btn-link-dark-dark {
    border: none !important;
    color: var(--secondary-color-dark) !important;
    background-color: transparent !important;
    text-decoration: underline;
}

.btn-tiny {
    padding: 5px 15px;
    font-size: 1rem;
    height: 40px;
}

.price-unit-selected {
    color: white;
    background: transparent linear-gradient(90deg, var(--primary-color-dark) 0%, var(--primary-color-light) 100%) 0% 0% no-repeat padding-box;
}

.price-unit-unselected {
    color: #212529;
    background: white;
}

.price-unit-selected:hover:not([disabled]) {
    background: var(--primary-color-dark) !important;
}

.form-control-lg {
    height: 52px;
}

.support-call,
.support-call > a {
    color: #7070a4 !important;
    text-align: center !important;
    margin-bottom: 0 !important;
    font-size: 0.875rem !important;
}

.checkbox {
    margin: 0;
    position: relative;
    display: flex;
}

.checkbox input[type='checkbox'] {
    width: auto;
    opacity: 0.00000001;
    position: absolute;
    top: 6.5px;
    left: 5.5px;
}

.checkbox-label {
    color: var(--secondary-color-dark);
}

.checkbox .checkbox-label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    margin: 4px;
    width: 14px;
    height: 14px;
    transition: transform 0.28s ease;
    border-radius: 3px;
    border: 1px solid var(--aw-border-color);
    background: transparent;
}

.checkbox .checkbox-label::after {
    content: '';
    display: block;
    width: 11px;
    height: 6px;
    border-bottom: 2px solid white;
    border-left: 2px solid white;
    -webkit-transform: rotate(-45deg) scale(0);
    transform: rotate(-45deg) scale(0);
    transition: transform ease 0.25s;
    will-change: transform;
    position: absolute;
    top: 6.5px;
    left: 5.5px;
}

.checkbox input[type='checkbox']:checked ~ .checkbox-label::before {
    border: none;
    background: transparent linear-gradient(90deg, #67c2ac 0%, #29e1b4 100%) 0% 0% no-repeat padding-box;
}

.checkbox input[type='checkbox']:checked ~ .checkbox-label::after {
    -webkit-transform: rotate(-45deg) scale(1);
    transform: rotate(-45deg) scale(1);
}

.checkbox:has(input[type='checkbox']:disabled) .checkbox-label {
    opacity: 50%;
    cursor: default;
}

.checkbox input[type='checkbox']:checked:disabled ~ .checkbox-label::before {
    border: none;
    background: transparent linear-gradient(90deg, #00000029 0%, var(--aw-border-color) 100%) 0% 0% no-repeat padding-box;
}

.checkbox input[type='checkbox']:checked:disabled ~ .checkbox-label::after {
    -webkit-transform: rotate(-45deg) scale(1);
    transform: rotate(-45deg) scale(1);
}

.checkbox .checkbox-label {
    display: block;
    padding-left: 26px !important;
    margin-bottom: 0 !important;
    font-weight: 400 !important;
    font-size: 0.875rem !important;
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
    cursor: pointer;
}

.checkbox .checkbox-label span {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.checkbox input[type='checkbox']:focus + .checkbox-label::before {
    outline: 3px solid lightblue;
}

// special round check boxes. E.g. used on the billing page
.circle-checkbox {
    width: 100%;
    margin: 15px auto;
    position: relative;
    display: block;
}

.circle-checkbox input[type='checkbox'] {
    width: auto;
    opacity: 0.00000001;
    position: absolute;
    left: 0;
    margin-left: -20px;
}

.circle-checkbox label {
    position: relative;
}

.circle-checkbox label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    margin: 4px;
    width: 22px;
    height: 22px;
    transition: transform 0.28s ease;
    border-radius: 5px;
    border: 2px solid var(--aw-border-color);
    background: transparent;
}

.circle-checkbox label:after {
    content: '';
    display: block;
    width: 14px;
    height: 7px;
    border-bottom: 3px solid white;
    border-left: 3px solid white;
    -webkit-transform: rotate(-45deg) scale(0);
    transform: rotate(-45deg) scale(0);
    transition: transform ease 0.25s;
    will-change: transform;
    position: absolute;
    top: 10px;
    left: 8px;
}

.circle-checkbox input[type='checkbox']:checked ~ label::before {
    border: none;
    background: transparent linear-gradient(90deg, #67c2ac 0%, #29e1b4 100%) 0% 0% no-repeat padding-box;
}

.circle-checkbox input[type='checkbox']:checked ~ label::after {
    -webkit-transform: rotate(-45deg) scale(1);
    transform: rotate(-45deg) scale(1);
}

.circle-checkbox label {
    display: block;
    padding-left: 40px;
    padding-top: 2px;
    margin-bottom: 0;
    font-weight: normal;
    cursor: pointer;
}

.circle-checkbox label span {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.circle-checkbox input[type='checkbox']:focus + label::before {
    outline: 3px solid lightblue;
}

.aw-radio-wrapper {
    display: flex;
    flex-direction: row;

    .aw-radio-label-span {
        font-weight: 400 !important;
        padding-left: 30px;
        margin-right: 1.25rem;
        font-size: 0.875rem;
        position: relative;
        top: 2px;
    }

    .aw-radio {
        position: relative;
    }

    .aw-radio *:hover {
        cursor: pointer;
    }

    .aw-radio input {
        height: 38px;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        width: 38px;
    }

    /*position new box*/
    .aw-radio input + label::before {
        display: block;
        content: ' ';
        background-image: url('../src/assets/icons/radio-checked.svg');
        background-color: white;
        background-size: 22px 22px;
        height: 22px;
        width: 22px;
        position: absolute;
        top: 5px;
    }

    /*radio pseudo element styles*/
    .aw-radio input + label::after {
        display: block;
        content: ' ';
        background-image: url('../src/assets/icons/radio-unchecked.svg');
        background-color: white;
        background-size: 22px 22px;
        height: 22px;
        width: 22px;
        position: absolute;
        top: 5px;
    }

    /*reveal check for 'on' state*/
    .aw-radio input:checked + label::after {
        visibility: hidden;
    }
}

.my-timepicker {
    display: flex;
    align-items: center;
}

.my-timepicker-hour,
.my-timepicker-meridian,
.my-timepicker-minute,
.my-timepicker-second {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.my-timepicker-input-container {
    width: 4em;
}

.my-timepicker-input {
    text-align: center;
}

.my-timepicker-spacer {
    width: 1em;
    text-align: center;
}

.aw-radio-wrapper-disabled {
    background-color: var(--aw-border-color);

    * {
        cursor: default !important;
    }
}

.custom-tool-tip-wrapper {
    position: relative;
}

.tool-tip-spacebetween {
    justify-content: space-between;
}

.tool-tip-placement-input {
    .placement-down {
        right: 0rem !important;
        top: 1.5rem !important;
    }
}

.placement-up {
    bottom: 30px;
}

.placement-down {
    top: 30px;
}

@keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeout {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

.tiny-title {
    font-size: 14px !important;
    margin-bottom: 0;
}

.refund-tooltip-title {
    margin-left: 0 !important;
}

.custom-tool-tip {
    color: white;
    padding: 16px;
    font-weight: 400;
    font-size: 0.875rem;
    position: absolute;
    z-index: 1001;
    max-width: 300px;
    background: linear-gradient(90deg, #03045e 0%, #0150b7 100%) no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
    animation: fadein 200ms ease-in;

    .tooltip-title {
        margin-left: 0.5rem;
        font-size: 1rem;
        font-weight: 600;
    }

    ul {
        margin-top: 1rem;
    }

    ul > li {
        color: white;
        padding-left: 0;
        margin-bottom: 0.125rem;
    }
}

.dropdown-item.active,
.dropdown-item:active {
    background-color: var(--primary-color-dark) !important;
    color: white !important;
}

.readOnlyFields {
    background-color: #e9ecef;
    opacity: 1;
    color: rgba(0, 0, 0, 0.45);
}

.bootstrap-select .dropdown-toggle:focus,
.bootstrap-select > select.mobile-device:focus + .dropdown-toggle {
    background-color: #fff !important;
    border-color: #80bdff !important;
    outline: 0 !important;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
    outline-offset: 0 !important;
}

.filter-option-inner-inner::after {
    /* Set content to be an attribute on the element for the country select on register page */
    content: attr(data-value);
}

.page-title {
    color: #03045e;
    font-weight: 600;
    margin: 0;
    padding: 0;
}

.add-right-margin {
    margin-right: 20px;
}

.card-list {
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-bottom: 54px;
    width: 100%;
    display: grid;
    grid-template-columns: 25fr 25fr 25fr 25fr;
    flex-wrap: wrap;
    gap: 1.125rem;

    div.subscription-state.mobile {
        display: none !important;
    }

    .card-item {
        cursor: pointer;
        margin: 0px;
        padding: 18px 23px 23px 23px;
        background-color: white;
        border-radius: 10px;
        min-width: 264px;
        height: 163px;
        border: none;
        box-shadow: 0px 3px 6px #00000029;

        table {
            width: 100%;
            table-layout: fixed;
            color: var(--secondary-color-dark);

            td.label {
                font-weight: 600;
            }

            .card-item-title-area {
                td {
                    .wrapper {
                        display: grid;
                        grid-template-columns: 75fr 25fr;

                        .title {
                            font-size: 1rem;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            font-weight: 600;
                        }
                    }
                }
            }

            td.value {
                text-align: right;
            }

            td.subscription-state {
                padding-top: 8px;
            }

            td.not-yet-used {
                font-style: italic;
                font-weight: 600;
            }

            td {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 0.75rem;

                hr {
                    margin-top: 6px;
                    margin-bottom: 8px;
                }

                hr.customized {
                    border: 1px solid var(--aw-border-color);
                    opacity: 0.5;
                }

                img {
                    height: 12px;
                    width: auto;
                    vertical-align: text-bottom;
                    padding-right: 2px;
                }

                .active {
                    color: var(--primary-color-dark);
                }

                .inactive {
                    color: #d81e5b;
                }

                .maintenance {
                    color: #e59824;
                }
            }

            td:empty::after {
                content: '\00a0'; // adds escaped &nbsp; after every originally empty td
            }
        }

        p {
            margin: 0;
            font-size: 0.875rem;

            span {
                font-weight: 600;
            }
        }

        .card-item-title {
            font-size: 1rem;
            color: var(--secondary-color-dark);
            font-weight: 600;
            overflow-x: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding-right: 5px;
        }
    }
}

/* 0 to 1100px */
@media (max-width: 1156px) {
    .card-list {
        grid-template-columns: 33.33fr 33.33fr 33.33fr;
    }
}

/* 0 to 860px */
@media (max-width: 860px) {
    .card-list {
        grid-template-columns: 50fr 50fr;
    }
}

/* 0 to 581px */
@media (max-width: 581px) {
    .card-list {
        grid-template-columns: 100fr;
        gap: 0.375rem;

        div {
            width: 100%;

            .card-item {
                height: 7.5rem;
                padding: 13px 17px 19px 17px;
            }
        }

        td.subscription-state.desktop {
            display: none;
        }

        div.subscription-state.mobile {
            display: flex !important;
            justify-content: flex-end;
            align-items: center;
            padding: 0 !important;
            white-space: nowrap;
        }
    }
}

.padding-wrapper {
    padding: 1rem;
}

.pac-container {
    z-index: 999999;

    span {
        color: var(--secondary-color-dark);
    }
}

.pac-container::after {
    display: none;
}

.toast-container {
    .btn {
        display: flex;
        align-items: center;
        float: right;
        margin-top: 10px;
    }

    .toast-close-button {
        font-size: 40px;
        margin-right: 10.5px;
        top: -0.65em;
        color: white;
        text-shadow: none;
    }

    .toast-close-button:hover {
        color: whitesmoke;
        opacity: 0.6;
        background-position: 100% 0;
        transition: all 0.4s ease-in-out;
        -o-transition: all 0.4s ease-in-out;
        -webkit-transition: all 0.4s ease-in-out;
        transition: all 0.4s ease-in-out;
    }

    .ngx-toastr {
        box-shadow: 0px 3px 6px #00000029 !important;
        border-radius: 5px;
        padding-top: 25px;
        min-height: 75px;
    }

    .toast-title {
        margin-bottom: -10px;
        margin-top: -10px;
        margin-left: -5px;
        text-align: left;
        font: normal normal 600 16px/18px Barlow;
        letter-spacing: 0px;
        color: #ffffff;
    }

    .toast-message {
        margin-top: 20px;
        margin-left: -33px;
        text-align: left;
        font: normal normal normal 14px/18px Barlow;
        color: #ffffff;

        .toast-span-bold {
            font: normal normal 600 16px/18px Barlow;
        }
    }

    //Position is set inside the background
    .toast-error {
        background:
            url('./assets/icons/modal-error-icon.svg') left 1rem top 0.9rem no-repeat,
            linear-gradient(90deg, #d81e5b 0%, #fe5e92 100%) no-repeat;
    }

    .toast-success {
        background:
            url('./assets/icons/modal-success-icon.svg') left 1rem top 0.9rem no-repeat,
            linear-gradient(90deg, #67c2ac 0%, #29e1b4 100%) no-repeat;
    }

    .toast-warning {
        background:
            url('./assets/icons/modal-warning-icon.svg') left 1rem top 0.9rem no-repeat,
            linear-gradient(90deg, #fc9124 0%, #ffb66b 100%) no-repeat;
    }

    .toast-info {
        background:
            url('./assets/icons/modal-info-icon.svg') left 1rem top 0.9rem no-repeat,
            linear-gradient(90deg, #03045e 0%, #0150b7 100%) no-repeat;
    }
}

.delete-btn {
    color: #7070a4 !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
    - Keep an eye out for whether or not this styling is redundant*/
.mat-form-field-appearance-legacy .mat-form-field-underline {
    display: none; // to remove underline on dropdown in the pagination bar on transactions and sub-customers lists.
}

// Swagger overwrites
.swagger-ui {
    // div:nth-child(2)>div:nth-child(4) {
    //     display: none;
    // }

    .view-line-link.copy-to-clipboard {
        width: 26px !important;
        margin: 0px 10px !important;
    }

    .parameter__name {
        text-wrap: nowrap;
    }

    .modal-ux-content {
        max-height: none !important;
    }

    .modal-btn.auth.authorize {
        background: transparent linear-gradient(90deg, var(--secondary-color-dark) 0%, #0150b7 100%) 0% 0% no-repeat padding-box !important;
        border: none !important;
        padding: 19px 32px !important;
        margin-right: 10px !important;
    }

    .modal-btn.auth.button {
        background: transparent linear-gradient(90deg, var(--secondary-color-dark) 0%, #0150b7 100%) 0% 0% no-repeat padding-box !important;
        border: none !important;
        padding: 19px 32px !important;
        margin-right: 10px !important;
    }

    .modal-btn.auth.btn-done {
        background: transparent linear-gradient(90deg, var(--primary-color-dark) 0%, var(--primary-color-light) 100%) 0% 0% no-repeat padding-box !important;
        border: none !important;
        padding: 15px 32px !important;
    }

    .authorize.locked {
        background: transparent linear-gradient(90deg, var(--secondary-color-dark) 0%, #0150b7 100%) 0% 0% no-repeat padding-box !important;
        border: none !important;
        padding: 15px 32px !important;
    }

    .authorize.unlocked {
        background: transparent linear-gradient(90deg, var(--secondary-color-dark) 0%, #0150b7 100%) 0% 0% no-repeat padding-box !important;
        border: none !important;
        padding: 15px 32px !important;
    }

    .try-out__btn {
        background: transparent linear-gradient(90deg, var(--secondary-color-dark) 0%, #0150b7 100%) 0% 0% no-repeat padding-box !important;
        border: none !important;
    }

    .try-out__btn:hover:not([disabled]) {
        background: var(--secondary-color-dark) !important;
    }

    .execute.opblock-control__btn {
        background: transparent linear-gradient(90deg, var(--primary-color-dark) 0%, var(--primary-color-light) 100%) 0% 0% no-repeat padding-box !important;
        margin-right: 15px !important;
    }

    .execute.opblock-control__btn:hover:not([disabled]) {
        background: var(--primary-color-dark) !important;
    }

    .btn-clear.opblock-control__btn {
        background: transparent linear-gradient(90deg, var(--secondary-color-dark) 0%, #0150b7 100%) 0% 0% no-repeat padding-box !important;
        margin-left: 15px !important;
    }

    .btn-clear.opblock-control__btn:hover:not([disabled]) {
        background: var(--secondary-color-dark) !important;
    }

    .opblock-control__btn {
        border: 10px !important;
        border-radius: 5px !important;
    }

    .scheme-container {
        box-shadow: none !important;
    }

    .body-param__example-edit {
        background-color: #ff6060 !important;
    }

    // .try-out__btn {
    //     display: none;
    // }
}

.modal-sm {
    max-width: 365px !important;
}

.modal-lg {
    max-width: 616px !important;
}

.modal-xl {
    max-width: 830px !important;
}

.mdc-form-field > label {
    padding-right: 0 !important;
    padding-left: 0 !important;
    margin-left: 0 !important;
    margin-bottom: 0.125rem;
    font-size: 14px !important;
    font-family: 'Barlow', sans-serif !important;
}

.mat-mdc-option {
    letter-spacing: 0 !important;
}

.mat-mdc-paginator {
    letter-spacing: 0 !important;
    user-select: none;
}

.mat-mdc-paginator-icon {
    fill: #03045e;
}

.mat-mdc-select-panel-above .mdc-menu-surface.mat-mdc-select-panel {
    border-radius: 4px !important;
}

.mat-mdc-option:hover:not(.mdc-list-item--disabled),
.mat-mdc-option:focus:not(.mdc-list-item--disabled),
.mat-mdc-option.mat-mdc-option-active,
.mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
    background: rgba(0, 0, 0, 0.12);
}

.mdc-notched-outline__leading {
    border-left: none !important;
    border-bottom: none !important;
    border-top: none !important;
}

.mdc-notched-outline__trailing {
    border-right: none !important;
    border-bottom: none !important;
    border-top: none !important;
}

.mdc-menu-surface.mat-mdc-select-panel {
    padding: 0px 0px !important;
}

.mdc-list-item__primary-text {
    color: #03045e;
}

.mdc-list-item--selected .mdc-list-item__primary-text,
.mdc-list-item--activated .mdc-list-item__primary-text {
    color: #03045e;
}

.mat-mdc-option.mdc-list-item {
    font-family: 'Barlow', sans-serif !important;
    font-weight: 500;
}

.mat-mdc-icon-button .mat-mdc-button-persistent-ripple::before {
    background-color: transparent !important;
}

.mat-mdc-radio-button .mdc-form-field label {
    cursor: pointer !important;
    letter-spacing: 0;
    font-size: 16px !important;
    margin: 0 5px 0 0;
}

.mat-mdc-radio-button.mat-accent .mdc-radio--disabled + label {
    color: #bec9d1 !important;
}

.mat-mdc-radio-button.mat-accent {
    --mat-radio-checked-ripple-color: transparent !important;
    --mat-radio-ripple-color: transparent !important;
}

.mdc-radio__ripple {
    display: none !important;
}

.mat-mdc-radio-button.mat-mdc-radio-checked .mat-ripple-element {
    opacity: 0 !important;
    /*click effect color change*/
    background: linear-gradient(180deg, #03045e, #0150b7);
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled + .mdc-radio__background .mdc-radio__inner-circle {
    background: linear-gradient(180deg, #03045e, #0150b7) !important;
    border-style: none;
    /*inner circle color change*/
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle {
    border-radius: 50%;
    border-style: solid;
    border-width: 2px;
    border-color: transparent !important;
    background-image: linear-gradient(white, white), radial-gradient(circle at top, #03045e, #0150b7) !important;
    background-origin: border-box;
    background-clip: padding-box, border-box;
}

.mdc-radio__native-control:disabled + .mdc-radio__background .mdc-radio__inner-circle {
    border-width: 8px;
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle {
    border-color: var(--aw-border-color) !important;
}

.mdc-radio {
    width: 1rem !important;
    height: 1rem !important;
}

.mdc-radio__background {
    width: 1rem !important;
    height: 1rem !important;
}

.mat-mdc-radio-button .mdc-radio {
    padding: 0 0.5rem 0 0 !important;
}

.mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
    background: transparent !important;
}

.mat-mdc-checkbox .mdc-checkbox__ripple {
    background: transparent !important;
}

.mat-mdc-checkbox .mdc-form-field {
    min-width: 350px;
    margin-left: -11px !important;
}

.mdc-checkbox__background {
    width: 16px !important;
    height: 16px !important;
}

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate='true']) ~ .mdc-checkbox__background {
    border: 1px solid var(--aw-border-color);
    border-color: rgba(0, 0, 0, 0.25) !important;
}

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background,
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate='true']:enabled ~ .mdc-checkbox__background {
    background-color: #29e1b4 !important;
    border-color: #29e1b4 !important;
    border: 1px solid #ced4da;

    svg path {
        fill: #29e1b4 !important;
    }
}

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:disabled:checked ~ .mdc-checkbox__background {
    background-color: #bec9d1 !important;
    border-color: #bec9d1 !important;
}

.mat-mdc-checkbox {
    height: 18px;
    width: 18px;
}

// removing arrows in inputfields,
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0;
    /* <-- Apparently some margin are still there even though it's hidden */
}

.line-title {
    font-size: 18px;
}

input[type='number'] {
    -moz-appearance: textfield;
    /* Firefox */
}

.label-link {
    text-decoration: underline;
}

.label-link:hover {
    cursor: pointer;
}

.d-row {
    display: flex;
    flex-direction: row;
}

.d-column {
    display: flex;
    flex-direction: column;
}

.margin-left {
    margin-left: auto;
}

.cdk-overlay-container {
    z-index: 1090;
}

.cdk-drag-preview {
    box-sizing: border-box;
    z-index: 1056 !important;
}

.cdk-drag-placeholder {
    opacity: 0;
}

.cdk-drag-animating,
.cdk-drop-list-dragging :not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.center-text {
    text-align: center;
}

.dragndrop-input {
    display: flex;
    width: 100%;
    align-items: center;
    min-height: 60px;
    height: fit-content;
    position: relative;
    background: #f1f5f8 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 3px #00000029;
    border-radius: 5px;

    .dragndrop-handle {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 24px;
        height: 100%;
        position: absolute;
        background-color: var(--aw-border-color);
        border-radius: 5px 0px 0px 5px;
        cursor: move;

        .dragndrop-dots {
            width: 12px;
            height: 29px;

            background: transparent url('assets/icons/dragndrop-dots.svg') 0% 0% no-repeat padding-box;
        }
    }

    .inner-content {
        width: 100%;
        height: fit-content;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        margin: 0rem 0.3rem 0rem 2.5rem;

        .inner-content-name {
            width: 40%;
            justify-content: center;

            label {
                font-weight: 600;
            }
        }

        .inner-content-price {
            width: 55%;
        }

        .inner-content-hover {
            width: 5%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 0.2rem;
        }

        #err-border:not(:focus-within) {
            border-color: #d81e5b;
        }

        .err-border:not(:focus-within) {
            border-color: #d81e5b;
        }
    }

    &:hover {
        .inner-content-hover {
            width: 5%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 0.2rem;

            .arrow-up {
                width: 19px;
                height: 19px;
                background: url('assets/icons/arrow-up-dragndrop.svg') 0% 0% no-repeat padding-box;
            }

            .arrow-down {
                width: 19px;
                height: 19px;
                background: url('assets/icons/arrow-down-dragndrop.svg') 0% 0% no-repeat padding-box;
            }

            .fake-arrow {
                width: 19px;
                height: 19px;
            }
        }
    }
}

.mobile-paginator {
    box-shadow: 0 3px 6px #00000029;
    margin-bottom: 20px;
    border-radius: 6px;

    * {
        border-radius: 6px;
    }

    .mat-mdc-paginator-range-actions {
        width: 100%;
        display: grid;
        grid-template-columns: 200fr 10fr 10fr 10fr 10fr;
    }

    .mat-mdc-paginator-range-label {
        margin-left: 16px;
    }

    .mat-mdc-paginator-container {
        align-items: center !important;
        justify-content: center !important;
        width: 100%;
        padding: 0;
        min-height: 3rem;
    }

    .mat-mdc-paginator-page-size {
        display: none;
    }
}

.coin-drop-wrapper {
    display: flex;
    flex-direction: column;
}

.coin-drop-radio-wrapper {
    display: flex;
    flex-direction: row;

    .coin-drop-radio {
        position: relative;
        border-radius: 5px;

        *:hover {
            cursor: pointer;
        }

        label {
            align-self: center;
            color: #03045e;
            font-size: 16px;
            font-weight: 400;
            display: block;
            padding: 5px 0px 5px 0px;
            margin: 0;
            width: 70px;
        }

        input {
            height: 38px;
            left: 0;
            opacity: 0;
            position: absolute;
            top: 0;
            width: 38px;
        }

        input + label::before {
            display: block;
            content: ' ';
            background-image: url('assets/icons/radio-checked.svg');
            background-color: white;
            background-size: 22px 22px;
            height: 22px;
            width: 22px;
            position: absolute;
            left: 35px;
            top: 7px;
        }

        input + label::after {
            display: block;
            content: ' ';
            background-image: url('assets/icons/radio-unchecked.svg');
            background-color: white;
            background-size: 22px 22px;
            height: 22px;
            width: 22px;
            position: absolute;
            left: 35px;
            top: 7px;
        }

        input:checked + label::after {
            visibility: hidden;
        }

        :focus-within {
            border-color: #80bdff;
            outline: 0;
            box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);

            /* For Windows high contrast mode. */
            outline: 3px solid transparent;
        }
    }
}

// Color of the ng-select searchable input
.ng-select .ng-select-container * {
    color: var(--aw-text-color);
}

.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
    border: 1px solid #ebeff1;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.location-hr-wrapper {
    border-top: 1px solid var(--aw-border-color);
    margin: 1.125rem 0rem;

    .custom-location-hr {
        min-width: 125px;
        width: fit-content;
        padding: 0 1rem;
        height: 25px;
        background-color: var(--aw-border-color);
        color: white;
        border-radius: 0 0 5px 5px;
        text-align: center;
    }
}

.highlight {
    background-color: #cef5ec;
    color: #03045e;
}

.hover-effect > div:first-child > .label-div > .highlight,
.hover-effect > div:last-child > .highlight {
    background-color: transparent;
    color: #fff;
}

// FullCalendar js css overrides
.aw-fc-close-icon {
    background: url('./assets/icons/cross-white.svg') no-repeat;
    background-size: 10px;
    max-width: 10px;
    min-height: 10px;
    height: auto;
    margin: 2px;
    border: none;
}

.fc-scrollgrid-section.fc-scrollgrid-section-header {
    background-color: #ffffff;

    th {
        .fc-scroller-harness > .fc-scroller {
            overflow: hidden !important;
        }
    }
}

.fc-scrollgrid {
    border: 0 !important;
}

.fc-event-time {
    justify-content: center !important;
}

.fc-scrollgrid-section-body {
    .fc-scroller::-webkit-scrollbar {
        width: auto;
        width: 11px;
        background-color: #ffffff;
    }

    .fc-scroller::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: #ebeff1;
    }

    .fc-scroller::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #bec9d1;
    }
}

.fc-timegrid-slot-label {
    font-weight: 600;
}

.fc-timegrid-cols {
    table {
        tbody {
            tr {
                td:first-child {
                    background-color: #ffffff;
                }
            }
        }
    }
}

.fc-more-popover .fc-body.fc-widget-content .fc-event-container {
    pointer-events: auto !important;
}

.fc-event-resizer {
    touch-action: none;
}

.fc-event-selected {
    touch-action: none;
}

.fc-view-harness,
.fc-view-harness-active {
    height: 50vh !important;
}

.ng-dropdown-panel {
    .scroll-host {
        &::-webkit-scrollbar {
            width: auto;
            width: 11px;
            background-color: #ffffff;
        }

        &::-webkit-scrollbar-track {
            border-radius: 10px;
            background-color: #ebeff1;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: #bec9d1;
        }
    }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
    .container {
        max-width: 1140px;
    }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .container {
        max-width: 1140px;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .container {
        max-width: 1140px;
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
