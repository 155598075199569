// Without feedback, used with all forms that don't have feedback (e.g transaction)
.input-group-wrapper {
    margin: 0 0 17.59px 0;

    label {
        font-weight: 600;
    }

    .aw-radio-wrapper {
        margin-bottom: 0.275rem;

        label {
            margin-bottom: 0;
        }
    }

    .aw-radio-wrapper:last-child {
        margin-bottom: 0;
    }
}

// With feedback, used with forms that have validation or error messages (e-g login, create new location ...)
.input-group-wrapper-feedback {
    margin: 0;

    label {
        font-weight: 600;
    }

    .aw-radio-wrapper {
        margin-bottom: 0.275rem;

        label {
            margin-bottom: 0;
        }
    }

    .aw-radio-wrapper:last-child {
        margin-bottom: 0;
    }
}

// use when there is no errors or assistive text, needed for forms with other input fields that have feedback
.add-fb {
    margin-bottom: 17.59px;
}

// INPUT FIELDS

.form-control,
.form-control:focus,
.form-control:active,
.form-select,
.form-select:focus,
.form-select:active {
    color: #03045e;
    //-webkit-text-fill-color: #03045E; //Safari overwrites the color values unless it is told not to
    border: 1px solid var(--aw-border-color);
    border-radius: 5px;
}

.err-border {
    border: 1px solid var(--error-color) !important;
}

// Error and Validation Feedback Styles
// Use this class as a styling for the error message that appears below the input field
.error-msg {
    color: var(--error-color);
    font-size: 11px;
    padding-top: 0.225rem;
    line-height: 0.875rem;
    font-weight: 600;
    margin-bottom: 0 !important;
}

// Assistive Text Styles, used for the description below the input field Should also include feedback-container class
.input-description {
    font-style: italic;
    font-size: 11px;
    padding-top: 0.225rem;
    line-height: 0.875rem;
    font-weight: 600 !important;
    margin-bottom: 0 !important;
}

// Use this height to adjust the height of the feedback container, so that the input field and the feedback message are aligned
// Creating white space between the input field and the feedback message
.feedback-container {
    min-height: 17.59px;
}

.google-toggler {
    border: 0 !important;
    background-color: #f8fafb;
    display: grid;
    grid-template-rows: 1fr auto;
    width: 100%;
    border-radius: 5px;
}

.pointer {
    cursor: pointer;
}

.google-toggler-text {
    font-size: 16px;
    font-weight: 600;
    color: #03045e;
}

.google-text-wrapper {
    width: auto;
    display: flex;
    justify-content: space-between;
}

.map-container {
    margin: 0.5rem 0 0 0;
    border: 1px solid #bec9d1;
    border-radius: 5px;
}

.disable-map {
    pointer-events: none;

    .google-toggler-text {
        color: #bec9d1;
    }

    img {
        opacity: 0.2;
    }
}

.control-panel-btn {
    cursor: pointer;
    width: 100%;
    background: linear-gradient(90deg, var(--primary-color-dark) 0%, var(--primary-color-light) 100%) 0 0 no-repeat padding-box;
    border-radius: 4px;
    height: 25px;
    color: white;
    border: none;
}
