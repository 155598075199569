.datepicker {

    .datepicker-picker {
        // overwriting the datepicker standard styles
        border-radius: 8px;
        box-shadow: 0px 3px 6px #00000029;

        .datepicker-header {
            padding: 0px;
            border-radius: 8px 8px 0px 0px;

            .datepicker-controls {
                background: transparent linear-gradient(90deg, #67C2AC 0%, #29E1B4 100%) 0% 0% no-repeat padding-box;
                padding: 0px;
                border-radius: 8px 8px 0px 0px;

                .button {
                    background-color: transparent;
                    border: none;
                    padding: 10px 8px 10px 8px;
                    color: white;
                    font-size: .9375rem;
                    font-weight: 600;
                }
            }
        }

        .datepicker-main {
            padding: 8px;

            .datepicker-view {
                .days {
                    .days-of-week {
                        .dow {
                            color: #03045E;
                        }
                    }

                    .datepicker-grid {
                        .focused {
                            background: transparent linear-gradient(90deg, #67C2AC 0%, #29E1B4 100%) 0% 0% no-repeat padding-box;
                            color: white;
                            font-weight: 600;
                        }

                        .range-end {
                            background-color: #BEC9D1;
                            color: white;
                            font-weight: 600;
                        }

                        .range {
                            background-color: #EBEFF1;
                        }

                        .prev,
                        .next {
                            color: #BEC9D1;
                        }

                        .selected {
                            background: transparent linear-gradient(90deg, var(--secondary-color-dark) 0%, #0150B7 100%) 0% 0% no-repeat padding-box;
                            color: white;
                        }

                    }
                }
            }
        }

        .datepicker-footer {
            background: transparent linear-gradient(90deg, #67C2AC 0%, #29E1B4 100%) 0% 0% no-repeat padding-box;
            box-shadow: inset 0 0 0 transparent;
            border-radius: 0px 0px 8px 8px;

            .datepicker-controls {
                display: flex;
                justify-content: space-between;
                height: 37px;

                .button {
                    background-color: transparent;
                    border: none;
                    color: white;
                    font-weight: 600;
                    font-size: .75rem;
                    padding: 0px 14px;
                }
            }
        }
    }


}