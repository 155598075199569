// FullCalendar overrides
.fc-col-header-cell *,
.fc-col-header-cell:hover * {
    color: var(--aw-text-color);
    text-decoration: none;
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
}

.fc-timegrid-event .fc-event-time,
.fc-timegrid-slot-label-cushion {
    font-size: .625rem;
}

.fc-timegrid-slots {
    * {
        height: .75rem !important;
        min-height: .75rem !important;
        max-height: .75rem !important;
        line-height: .75rem !important;
    }
}

.fc .fc-timegrid-slot-minor {
    // border-top-style: solid;
    // border-top-color: lightgray;
    border-top: none;
}

.fc-timegrid-event-short .fc-event-main-frame,
.fc-v-event .fc-event-time {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.fc-timegrid-event-short .fc-event-time::after {
    content: none;
}

.fc-v-event .fc-event-title-container {
    display: none;
}

.fc-event-draggable:focus::before,
.fc-event-draggable:focus::after,
.fc-event-draggable:active::before,
.fc-event-draggable:active::after {
    // For happy hour calendar dragable timeslots should not have special styling
    content: none;
    background-color: var(--fc-event-bg-color);
}

a.fc-event-draggable {
    text-decoration: none !important;
    border-radius: 0.3125rem !important;
}